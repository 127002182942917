/* For overriding not customizable styles for HighCharts */

:fullscreen .highcharts-background {
  fill: #fff;
}

input.highcharts-range-selector {
  border-bottom: 1px solid #000 !important;
  outline: none !important;
}

input.highcharts-range-selector:focus,
input.highcharts-range-selector:active {
  border: none !important;
  border-bottom: 2px solid #000 !important;
  width: 135px !important;
}